import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointService {
  public static API_ENDPOINTS = {
    baseURL: '/roa-mobile/rest',
    endpoints: {
      getSraYearsPeriod: {
        url: '/BankingMetadataService/SraYearsPeriod',
        method: 'GET',
      },
      getSraTaxCodes: {
        url: '/BankingMetadataService/SraTaxCodes',
        method: 'GET',
      },
      getZimraTaxAccounts: {
        url: '/BankingMetadataService/ZimraTaxAccounts',
        method: 'GET',
      },
      getZimraTaxCodes: {
        url: '/BankingMetadataService/ZimraTaxCodes',
        method: 'GET',
      },
      getZimraTaxOfficeCodes: {
        url: '/BankingMetadataService/ZimraTaxOfficeCodes',
        method: 'GET',
      },
      getFileUploadSourceAccountCurrencies: {
        url: '/BankingMetadataService/FileUploadSourceAccountCurrencies',
        method: 'GET',
      },
      fetchOffers: {
        url: '/AccountService/Offers',
        method: 'POST',
      },
      downloadKfdDocument: {
        url: '/AccountService/DownloadKfdDocument',
        method: 'POST',
        responseType: 'arraybuffer',
      },
      RedrawLoan: {
        url: '/AccountService/RedrawLoan',
        method: 'POST',
      },
      fetchOfferApplicationSettings: {
        url: '/BankingMetadataService/ApplicationSettings',
        method: 'POST',
      },
      viewStatementMini: {
        url: '/AccountService/Statement',
        method: 'POST',
      },
      UplFeeAndInsurance: {
        url: '/AccountService/UplFeeAndInsurance',
        method: 'POST',
      },
      getAccountDetails: {
        url: '/AccountService/AccountDetails',
        method: 'POST',
      },
      uplOfferDetails: {
        url: '/AccountService/UplOfferDetails',
        method: 'POST',
      },
      authenticate: {
        url: '/SecurityService/AuthenticateDI',
        method: 'POST',
      },
      accounts: {
        url: '/AccountService/Accounts',
        method: 'POST',
      },
      statement: {
        url: '/AccountService/Statement',
        method: 'POST',
      },
      renameAccount: {
        url: '/AccountService/RenameAccount',
        method: 'POST',
      },
      debitCards: {
        url: '/AccountService/DebitCards',
        method: 'POST',
      },
      stopDebitCards: {
        url: '/AccountService/StopDebitCard',
        method: 'POST',
      },
      stopDebitCardsTZ: {
        url: '/ServiceRequestService/StopDebitCard',
        method: 'POST',
      },
      activateDebitCardsTZ: {
        url: '/ServiceRequestService/ActivateDebitCard',
        method: 'POST',
      },
      updateLimitDebitCards: {
        url: '/AccountService/UpdateDebitCardLimits',
        method: 'POST',
      },
      banksList: {
        url: '/BankingMetadataService/FetchBankList',
        method: 'POST',
      },
      bankBranches: {
        url: '/BankingMetadataService/FetchBranchListByBank',
        method: 'POST',
      },
      branchRoutes: {
        url: '/BankingMetadataService/FetchRouteListByBranch',
        method: 'POST',
      },
      resendOtp: {
        url: '/SecurityService/ResendStepUp',
        method: 'POST',
      },
      transactions: {
        url: '/TransactionService/Transactions',
        method: 'PUT',
      },
      editScheduleTransaction: {
        url: '/TransactionService/EditTransaction',
        method: 'PUT',
      },
      updateEapLimits: {
        url: '/ServiceRequestService/UpdateEapLimits',
        method: 'POST',
      },
      cancelScheduleTransactions: {
        url: '/TransactionService/StopPayment',
        method: 'POST',
      },
      cancelNextScheduleTransactions: {
        url: '/TransactionService/StopNextPayment',
        method: 'POST',
      },
      fetchReceipts: {
        url: '/TransactionService/FetchReceipts',
        method: 'POST',
      },
      documentDownloadFile: {
        url: '/SmeService/DownloadInternationalPaymentDocument',
        method: 'POST',
      },
      documentFileList: {
        url: '/SmeService/InternationalPaymentDocumentList',
        method: 'POST',
      },
      fetchVASReceipts: {
        url: '/TransactionService/FetchVasReceipts',
        method: 'POST',
      },
      fetchInstantMoneyReceipts: {
        url: '/TransactionService/FetchInstantMoneyReceipts',
        method: 'POST',
      },
      receiptDetails: {
        url: '/TransactionService/ReceiptDetails',
        method: 'POST',
      },
      groups: {
        url: '/RecipientService/Groups',
        method: 'POST',
      },
      cmagroups: {
        url: '/CmaService/GetCmaGroups',
        method: 'POST',
      },
      groupsPUT: {
        url: '/RecipientService/Groups',
        method: 'PUT',
      },
      addCmaGroup: {
        url: '/CmaService/AddCmaGroup',
        method: 'POST',
      },
      editCmaGroups: {
        url: '/CmaService/EditCmaGroup',
        method: 'POST',
      },
      deleteGroups: {
        url: '/RecipientService/DeleteGroups',
        method: 'POST',
      },
      deleteCMAGroups: {
        url: '/CmaService/DeleteCmaGroup',
        method: 'POST',
      },
      recipients: {
        url: '/RecipientService/Recipients',
        method: 'POST',
      },
      cmarecipientsPUT: {
        url: '/CmaService/AddCmaBeneficiary',
        method: 'POST',
      },
      editCmaRecipients: {
        url: '/CmaService/EditCmaRecipient',
        method: 'POST',
      },
      cmarecipients: {
        url: '/CmaService/GetCmaRecipients',
        method: 'POST',
      },
      deleteRecipients: {
        url: '/RecipientService/DeleteRecipients',
        method: 'POST',
      },
      deleteCMARecipients: {
        url: '/CmaService/DeleteCmaRecipient',
        method: 'POST',
      },
      recipient: {
        url: '/RecipientService/Recipient',
        method: 'POST',
      },
      cmarecipient: {
        url: '/CmaService/GetCmaRecipient',
        method: 'POST',
      },
      recipientsPUT: {
        url: '/RecipientService/Recipients',
        method: 'PUT',
      },
      addCmaBeneficiary: {
        url: '/CmaService/AddCmaBeneficiary',
        method: 'POST',
      },
      recipientsgroupPUT: {
        url: '/CmaService/EditCmaRecipient',
        method: 'POST',
      },
      exchangeRate: {
        url: '/BankingMetadataService/ExchangeRates',
        method: 'POST',
      },
      countryCodes: {
        url: '/BankingMetadataService/CountryCodes',
        method: 'GET',
      },
      cmacountryCodes: {
        url: '/BankingMetadataService/CmaCountryCodes',
        method: 'GET',
      },
      cmaBeneTypes: {
        url: '/BankingMetadataService/CmaBeneficiaryTypes',
        method: 'GET',
      },
      publicHolidaysList: {
        url: '/BankingMetadataService/PublicHolidays',
        method: 'GET',
      },
      settings: {
        url: '/BankingMetadataService/Settings',
        method: 'GET',
      },
      startSession: {
        url: '/SecurityService/StartSession',
        method: 'GET',
      },
      registeredBiller: {
        url: '/BillerService/GetRegisteredBillers',
        method: 'POST',
      },
      registeredCdiBiller: {
        url: '/CdiService/GetRegisteredCdiRecipients',
        method: 'POST',
      },
      GetCdiGroups: {
        url: '/CdiService/GetCdiGroups',
        method: 'POST',
      },
      deleteBiller: {
        url: '/BillerService/DeRegisterFromBiller',
        method: 'POST',
      },
      deleteCdiBiller: {
        url: '/CdiService/UnregisterCdiRecipient',
        method: 'POST',
      },
      billerGroup: {
        url: '/BillerService/GetBillerGroups',
        method: 'POST',
      },
      addBillerGroup: {
        url: '/BillerService/AddBillerGroup',
        method: 'POST',
      },
      addCdiBillerGroup: {
        url: '/CdiService/AddCdiGroup',
        method: 'POST',
      },
      editBillerGroup: {
        url: '/BillerService/EditBillerGroup',
        method: 'POST',
      },
      deleteBillerGroup: {
        url: '/BillerService/DeleteBillerGroup',
        method: 'POST',
      },
      deleteCdiGroup: {
        url: '/CdiService/DeleteCdiGroup',
        method: 'POST',
      },
      editCdiBillerGroup: {
        url: '/CdiService/EditCdiGroup',
        method: 'POST',
      },
      editBiller: {
        url: '/BillerService/EditRegisteredBiller',
        method: 'POST',
      },
      editCdiBiller: {
        url: '/CdiService/EditCdiRecipient',
        method: 'POST',
      },
      allCdiBiller: {
        url: '/CdiService/GetAllCdiRecipients',
        method: 'POST',
      },
      allBiller: {
        url: '/BillerService/GetAllBillers',
        method: 'GET',
      },
      addBiller: {
        url: '/BillerService/RegisterForBiller',
        method: 'POST',
      },
      addCdiBiller: {
        url: '/CdiService/RegisterCdiRecipient',
        method: 'POST',
      },
      transactionManagementVasReceipt: {
        url: '/TransactionService/FetchVasReceipts',
        method: 'POST',
      },
      transactionManagementIndividualReceiptBulkInstantMoney: {
        url: '/TransactionService/GetBulkInstantMoneyIndividualReceipt',
        method: 'POST',
      },
      getProviderDetails: {
        url: '/PrepaidService/ProviderDetails',
        method: 'GET',
      },
      getAllGepgBillers: {
        url: '/RecipientService/GetAllGepgBillers',
        method: 'GET',
      },
      fetchGepgReceipts: {
        url: '/TransactionService/FetchGepgReceipts',
        method: 'POST',
      },
      validateGepgBiller: {
        url: '/TransactionService/ValidateGepgBiller',
        method: 'POST',
      },
      fetchBundleListByProvider: {
        url: '/PrepaidService/FetchBundleListByProvider',
        method: 'POST',
      },
      fetchVoucherListByBundle: {
        url: '/PrepaidService/FetchVoucherListByBundle',
        method: 'POST',
      },
      serviceRequests: {
        url: '/ServiceRequestService/ServiceRequests',
        method: 'POST',
      },
      newServiceRequest: {
        url: '/ServiceRequestService/ServiceRequestTypes',
        method: 'GET',
      },
      individualServiceRequests: {
        url: '/ServiceRequestService/ServiceRequestDetails',
        method: 'POST',
      },
      fetchRetailSecurityChallenge: {
        url: '/SecurityService/FetchRetailSecurityChallenge',
        method: 'POST',
      },
      getAccountHolderName: {
        url: '/RecipientService/RecipientValidation',
        method: 'POST',
      },
      futureTransactions: {
        url: '/TransactionService/FutureTransactions',
        method: 'POST',
      },
      futureTransactionsReceipt: {
        url: '/TransactionService/FutureTransactionDetails',
        method: 'POST',
      },
      cancelInstant: {
        url: '/TransactionService/CancelInstantMoneyVoucher',
        method: 'POST',
      },
      instantMoneyVoucherList: {
        url: '/BankingMetadataService/InstantMoneyVoucherList',
        method: 'GET',
      },
      instantMoneyPurposeList: {
        url: '/BankingMetadataService/InstantMoneyPurposeList',
        method: 'GET',
      },
      registerIBUser: {
        url: '/SecurityService/RegisterIBUser',
        method: 'POST',
      },
      registerSecurityQuestions: {
        url: '/SecurityService/RegisterSecurityQuestions',
        method: 'POST',
      },
      IBSecurityChallenge: {
        url: '/SecurityService/IBSecurityChallenge',
        method: 'POST',
      },
      fetchSecurityQuestions: {
        url: '/SecurityService/FetchIBSecurityChallenge',
        method: 'POST',
      },
      changeIBPassword: {
        url: '/SecurityService/ChangeIBPassword',
        method: 'POST',
      },
      logout: {
        url: '/SecurityService/Logout',
        method: 'POST',
      },
      setRetailSecurityQuestions: {
        url: '/SecurityService/SetRetailSecurityQuestions',
        method: 'POST',
      },
      fetchUsernameSuggestions: {
        url: '/SecurityService/FetchUsernameSuggestions',
        method: 'GET',
      },
      changeUsername: {
        url: '/SecurityService/ChangeUsername',
        method: 'POST',
      },
      validateUsername: {
        url: '/SecurityService/ValidateUsername',
        method: 'POST',
      },
      internationalPaymentCountryCodes: {
        url: '/BankingMetadataService/InternationalPaymentCountryCodes',
        method: 'GET',
      },
      fetchInternationalPaymentRouteDetails: {
        url: '/BankingMetadataService/FetchRouteDetails',
        method: 'POST',
      },
      internationalPaymentReasons: {
        url: '/BankingMetadataService/InternationalPaymentReasons',
        method: 'GET',
      },
      loadOverdraft: {
        url: '/AccountService/LoadOverdraft',
        method: 'POST',
      },
      internationalPaymentChargeOptionList: {
        url: '/BankingMetadataService/ChargeOptionList',
        method: 'GET',
      },
      internationalPaymentCurrencyList: {
        url: '/BankingMetadataService/InternationalPaymentAvailableCurrencyList',
        method: 'GET',
      },
      internationalPaymentPreInitialisation: {
        url: '/TransactionService/PreInitiateInternationalPayment',
        method: 'POST',
      },
      getApproverRules: {
        url: '/SmeService/GetApproverRules',
        method: 'POST',
      },
      getApprovals: {
        url: '/SmeService/GetApprovals',
        method: 'POST',
      },
      bulkInstantMoney: {
        url: '/SmeService/BulkInstantMoney',
        method: 'POST',
      },
      DownloadFile: {
        url: '/SmeService/DownloadDocument',
        method: 'POST',
      },
      DownloadBulkInstantFile: {
        url: '/SmeService/DownloadBulkInstantMoney',
        method: 'POST',
      },
      approval: {
        url: '/SmeService/Approval',
        method: 'POST',
      },
      accountModification: {
        url: '/ServiceRequestService/ModifyAccount',
        method: 'POST',
      },
      getCmaRecipients: {
        url: '/CmaService/GetCmaRecipients',
        method: 'POST',
      },
      fetchBulkInstantMoneyReceipts: {
        url: '/TransactionService/FetchBulkInstantMoneyReceipts',
        method: 'POST',
      },
      cmaPurposeCodes: {
        url: '/BankingMetadataService/CmaPurposeCodes',
        method: 'GET',
      },
      cmaSubPurposeCodes: {
        url: '/BankingMetadataService/CmaSubPurposeCodes',
        method: 'POST',
      },
      getCmaGroups: {
        url: '/CmaService/GetCmaGroups',
        method: 'POST',
      },
      cmaCountryCodes: {
        url: '/BankingMetadataService/CmaCountryCodes',
        method: 'GET',
      },
      cmaBeneficiaryTypes: {
        url: '/BankingMetadataService/CmaBeneficiaryTypes',
        method: 'GET',
      },
      getAvailableBonusInvestmentPeriodList: {
        url: '/BankingMetadataService/AvailableBonusInvestmentPeriodList',
        method: 'GET',
      },
      getAccountOpeningNotificationMethods: {
        url: '/BankingMetadataService/AccountOpeningNotificationMethods',
        method: 'GET',
      },
      sendBonusInvestmentRequest: {
        url: '/AccountOpeningService/OpenBonusInvestmentAccount',
        method: 'POST',
      },
      sendOpenCallDepositAccount: {
        url: '/AccountOpeningService/OpenCallDepositAccount',
        method: 'POST',
      },
      getMinimumAmountPerCurrencyList: {
        url: '/BankingMetadataService/SavingsAccountOpeningMinimalBalanceList',
        method: 'GET',
      },
      getCallDepositAccountOpeningMinimalBalanceList: {
        url: '/BankingMetadataService/CallDepositAccountOpeningMinimalBalanceList',
        method: 'GET',
      },
      sendOpenPureSaveAccount: {
        url: '/AccountOpeningService/OpenPureSaveAccount',
        method: 'POST',
      },
      sendOpenCurrentAccount: {
        url: '/AccountOpeningService/OpenCurrentAccount',
        method: 'POST',
      },
      getCurrentAccountTypeList: {
        url: '/BankingMetadataService/CurrentAccountTypeList',
        method: 'GET',
      },
      sendOpenFixedDepositAccount: {
        url: '/AccountOpeningService/OpenFixedDepositAccount',
        method: 'POST',
      },
      sendOpenSibekeloAccount: {
        url: '/AccountOpeningService/OpenSibekeloDepositAccount',
        method: 'POST',
      },
      getSibekeloDepositPeriodList: {
        url: '/BankingMetadataService/AvailableSibekeloDepositPeriodList',
        method: 'GET',
      },
      getAvailableDepositPeriodList: {
        url: '/BankingMetadataService/AvailableDepositPeriodList',
        method: 'GET',
      },
      reIssueToken: {
        url: '/SecurityService/ReIssueToken',
        method: 'GET',
      },
      cardForSystemPrincipalId: {
        url: '/SecurityService/CardForSystemPrincipalId',
        method: 'POST',
      },
      loanProductList: {
        url: '/BankingMetadataService/LoanProductList',
        method: 'GET',
      },
      contactTimeList: {
        url: '/BankingMetadataService/ContactTimeList',
        method: 'GET',
      },
      applyForSimpleLoan: {
        url: '/ServiceRequestService/ApplyForSimpleLoan',
        method: 'POST',
      },
      applyForLoan: {
        url: '/ServiceRequestService/ApplyForLoan',
        method: 'POST',
      },
      depositBook: {
        url: '/ServiceRequestService/DepositBook',
        method: 'POST',
      },
      fixedDepositBreakage: {
        url: '/ServiceRequestService/FixedDepositBreakage',
        method: 'POST',
      },
      blueSky: {
        url: '/ServiceRequestService/BlueSky',
        method: 'POST',
      },
      availableFixedDepositPeriods: {
        url: '/BankingMetadataService/AvailableRenewalPeriodList',
        method: 'GET',
      },
      renewalInstructions: {
        url: '/BankingMetadataService/RenewalInstructionList',
        method: 'GET',
      },
      renewFixedDeposit: {
        url: '/ServiceRequestService/RenewFixedDepositAccount',
        method: 'POST',
      },
      overDraftLimitUpdate: {
        url: '/ServiceRequestService/ChangeOverdraftLimit',
        method: 'POST',
      },
      stopChequeReason: {
        url: '/BankingMetadataService/ChequeStopReasonList',
        method: 'GET',
      },
      stopCheque: {
        url: '/ServiceRequestService/StopCheque',
        method: 'POST',
      },
      chequeBookBranches: {
        url: '/BankingMetadataService/ChequeBookBranches',
        method: 'GET',
      },
      numberOfLeaves: {
        url: '/BankingMetadataService/NumberOfLeavesList',
        method: 'GET',
      },
      chequeBookRequest: {
        url: '/ServiceRequestService/ChequeBookRequest',
        method: 'POST',
      },
      uploadDocument: {
        url: '/SmeService/UploadDocument',
        method: 'POST',
      },
      valueValidationInternationalpayment: {
        url: '/SmeService/CheckDocumentUploadRequired',
        method: 'POST',
      },
      countryBopCodes: {
        url: '/BankingMetadataService/InternationalPaymentSubReasons',
        method: 'GET',
      },
      bopUploadDocument: {
        url: '/file-upload',
        method: 'POST',
      },
      gipAccountDetails: {
        url: '/AccountService/GipAccountDetails',
        method: 'POST',
      },
      atmDebitCard: {
        url: '/ServiceRequestService/AtmDebitCard',
        method: 'POST',
      },
      ebankingLimitAdjustment: {
        url: '/ServiceRequestService/EBankingLimitAdjustment',
        method: 'POST',
      },
      bankCertifiedCheque: {
        url: '/ServiceRequestService/RequestBankCertifiedCheque',
        method: 'POST',
      },
      collectionBranch: {
        url: '/BankingMetadataService/ChequeBookBranches',
        method: 'GET',
      },
      validateVasInput: {
        url: '/PrepaidService/ValidateVasInput',
        method: 'POST',
      },
      atmLimitAdjustment: {
        url: '/ServiceRequestService/AtmLimitAdjustment',
        method: 'POST',
      },
      creditCard: {
        url: '/ServiceRequestService/CreditCard',
        method: 'POST',
      },
      renewSibekeloAccount: {
        url: '/ServiceRequestService/RenewSibekeloAccount',
        method: 'POST',
      },
      requestInsurance: {
        url: '/ServiceRequestService/BankAssurance',
        method: 'POST',
      },
      fixedDepositInterestRate: {
        url: '/ServiceRequestService/FixedDepositInterestRate',
        method: 'POST',
      },
      creditCardStatement: {
        url: '/AccountService/CreditCardStatement',
        method: 'POST',
      },
      getMoneyTransferTaxCodes: {
        url: '/BankingMetadataService/MoneyTransferTaxCodes',
        method: 'GET',
      },
      cancelNoticeGivenAccount: {
        url: '/ServiceRequestService/CancelNotice',
        method: 'POST',
      },
      giveNoticeOnNoticeDeposit: {
        url: '/ServiceRequestService/GiveNotice',
        method: 'POST',
      },
      getAvailableCurrencyList: {
        url: '/BankingMetadataService/AvailableCurrencyList',
        method: 'GET',
      },
      sendOpenNoticeDepositAccount: {
        url: '/AccountOpeningService/OpenNoticeDepositAccount',
        method: 'POST',
      },
      InsuranceReasons: {
        url: '/BankingMetadataService/BankAssuranceProductList',
        method: 'GET',
      },
      openSavingsAccount: {
        url: '/AccountOpeningService/OpenSavingsAccount',
        method: 'POST',
      },
      accountOpeningCurrencyList: {
        url: '/BankingMetadataService/CurrencyList',
        method: 'GET',
      },
      getMinAmountPerCurrencyListCallDeposit:{
        url: "/BankingMetadataService/CallDepositAccountOpeningMinimalBalanceList",
        method: "GET"
      },
      posReversal: {
        url: '/ServiceRequestService/POSReversals',
        method: 'POST',
      },
      mobileTopUpAmountList: {
        url: '/BankingMetadataService/AvailableMobileTopUpAmountList',
        method: 'GET',
      },
      indicativeReinvestmentDetails: {
        url: '/AccountService/IndicativeReinvestmentDetails',
        method: 'POST',
      },
      updateReinvestmentDetails: {
        url: '/AccountService/UpdateReinvestmentDetails',
        method: 'POST',
      },
      fetchQrPaymentDetails: {
        url: '/TransactionService/FetchQrPaymentDetails',
        method: 'POST',
      },
      getEapLimitSchemes: {
        url: '/BankingMetadataService/EapLimitSchemes',
        method: 'GET',
      },
      getFetchLimitSchemeDefinition: {
        url: '/TransactionService/FetchLimitSchemeDefinition',
        method: 'POST',
      },
      getSchoolInstitutions: {
        url: '/BankingMetadataService/SchoolInstitutions',
        method: 'GET',
      },
      getInstitutionBillDetails: {
        url: '/TransactionService/GetInstitutionBillDetails',
        method: 'POST',
      },

      ServiceRequestDetails: {
        url: '/ServiceRequestService/ServiceRequestDetails',
        method: 'POST',
      },
      getStampedStatementCost: {
        url: '/BankingMetadataService/StampedStatementFees',
        method: 'GET',
      },
      downloadStampedStatement: {
        url: '/AccountService/DownloadStampedStatement',
        method: 'POST',
      },
      UnayoInstantMoneyFee: {
        url: '/TransactionService/UnayoInstantMoneyFee',
        method: 'POST',

      },
      downloadAccountConfirmationLetter: {
        url: '/AccountService/DownloadAccountConfirmationLetter',
        method: 'POST',
      },
      paymentSubPurposes: {
        url: '/BankingMetadataService/PaymentSubPurposes',
        method: 'GET',
      },
      sector: {
        url: '/BankingMetadataService/PaymentSectorCodes',
        method: 'GET',
      },
      leadLagCurrency: {
        url: '/TransactionService/CurrencyPairDetails',
        method: 'POST',
      },
    },
  };
}
