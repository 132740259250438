import { createAction, props } from '@ngrx/store';

export const beneDetails = createAction(
  '[International Payment Component] beneDetails',
  props<{ beneDetails: object }>()
);
export const routeData = createAction('[International Payment Component] routeData', props<{ routeData: object }>());
export const preInitiateData = createAction(
  '[International Payment Component] preInitiateData',
  props<{ preInitiateData: object }>()
);
export const receiptData = createAction(
  '[International Payment Component] receiptData',
  props<{ receiptData: object }>()
);
export const exchangeRateType = createAction(
  '[International Payment Component] exchangeRateType',
  props<{ exchangeRateType: string }>()
);
export const paymentPayload = createAction(
  '[International Payment Component] paymentPayload',
  props<{ paymentPayload: object }>()
);
