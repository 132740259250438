import { ApproverDataService } from './../../services/approver-data/approver-data.service';
import { setNonFinancialApprovalData, setFinancialApprovalData } from './../../shared/store-utilities/actions/approval.action';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as loginAction from '../../shared/store-utilities/actions/login.action';

import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { ReceiptRequest } from './data/receipt-request';
import { InstantMoneyReceiptsResponse } from './data/instant-money-receipts-response';
import { BulkInstantMoneyReceiptsResponse } from './data/bulk-instant-money-receipts-response';
import { TransactionsRequest } from './data/transactions-request';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import moment from 'moment';
import { TransactionsResponse } from './data/transactions-response';
import * as dashboardActions from '../../shared/store-utilities/actions/dashboard.action';
import { McaHeader } from '../data/mca-header';
import { InstitutionBillDetailsRequest } from './data/school-institutions/institution-bill-details-request';
import { InstitutionBillDetailsResponse } from './data/school-institutions/institution-bill-details-response';

@Injectable()
export class TransactionService {
  config = environment.config;
  accountsList;
  dashboardData;

  constructor(private serviceEndpoint: ServiceEndpointsService, private approverData: ApproverDataService, private store: Store<any>) {
    this.store.select('appReducer', 'dashboardReducer').subscribe((stateData) => {
      this.dashboardData = _.cloneDeep(stateData.accounts);
      if (stateData.accounts && stateData.accounts.body && stateData.accounts.body.accounts.length) {
        this.accountsList = stateData.accounts.body.accounts;
      }
    });
  }

  getResponseData(res, type = null) {
    const responseCode: any = {
      type: '',
      message: '',
    };

    if (
      res &&
      res.body &&
      res.body.transactionResults &&
      res.body.transactionResults[0] &&
      res.body.transactionResults[0].responseCode &&
      res.body.transactionResults[0].responseCode.responseType
    ) {
      responseCode.type = res.body.transactionResults[0].responseCode.responseType;
    } else {
      responseCode.type = res.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);
    }
    if (
      res &&
      res.body &&
      res.body.transactionResults &&
      res.body.transactionResults[0] &&
      res.body.transactionResults[0].responseCode &&
      res.body.transactionResults[0].responseCode.message
    ) {
      responseCode.message = res.body.transactionResults[0].responseCode.message;
    } else {
      responseCode.message = res.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
    }
    // responseCode.type = res.body.transactionResults[0].responseCode.responseType || res.headers.get('x-sbg-response-type');
    // responseCode.message = res.body.transactionResults[0].responseCode.message || res.headers.get('x-sbg-response-message');
    if (type === 'CANCEL_INSTANT_MONEY_VOUCHER') {
      if (responseCode.type === 'OK' || responseCode.type === 'SUCCESS') {
        if (this.config.localCurrency === 'NAD') {
          responseCode.message = 'BlueVoucher Cancelled Successfully';
        } else {
          responseCode.message = 'Instant Money Cancelled Successfully';
        }
      } else {
        if (this.config.localCurrency === 'NAD') {
          responseCode.message = 'BlueVoucher cancellation failed';
        } else {
          responseCode.message = 'Instant Money cancellation failed';
        }
      }
    }

    return responseCode;
  }

  extractLocalCurrencyLimits(accountSummary) {
    const limitsArray = accountSummary.body.cardProfile.limits;

    accountSummary = _.cloneDeep(accountSummary);

    if (limitsArray) {
      for (let i = limitsArray.length - 1; i >= 0; i--) {
        const value = limitsArray[i];
        if (value.amount.currency === this.config.localCurrency) {
          switch (value.type) {
            case 'DAILY':
              accountSummary.body.cardProfile.dailyWithdrawalLimit = value.amount;
              break;
            case 'DAILY_UTILIZED':
              accountSummary.body.cardProfile.usedEAPLimit = value.amount;
              break;
            case 'MONTHLY':
              accountSummary.body.cardProfile.monthlyWithdrawalLimit = value.amount;
              break;
            case 'MONTHLY_UTILIZED':
              accountSummary.body.cardProfile.monthlyUsedLimit = value.amount;
              break;
            case 'MONTHLY_SELF_FUNDS':
              accountSummary.body.cardProfile.monthlySFTWithdrawalLimit = value.amount;
              break;
            case 'MONTHLY_SELF_FUNDS_UTILIZED':
              accountSummary.body.cardProfile.monthlySFTUsedLimit = value.amount;
              break;
            default:
              break;
          }
        }
      }
    }
    return accountSummary;
  }

  /**
   * @deprecated Use makeTransaction() instead
   */
  getTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'transactions').pipe(
      map((res: any) => {
        let cardProfileUpdatedValue;

        if (res.body && res.body.account[0]) {
          const response = this.extractLocalCurrencyLimits(res);
          cardProfileUpdatedValue = response.body.cardProfile;

          res.body.account.forEach((acc) => {
            const fromAccountUpdatedData = acc;
            let index;
            if (this.accountsList && this.accountsList.length && fromAccountUpdatedData && fromAccountUpdatedData.number) {
              index = _.findIndex(this.accountsList, { number: fromAccountUpdatedData.number });
            }

            if (index >= 0) {
              /* Update Accounts array */
              const updatedAccountsArray = this.accountsList.slice(0);
              updatedAccountsArray[index] = fromAccountUpdatedData;
              this.accountsList = _.cloneDeep(updatedAccountsArray);
              this.dashboardData.body.accounts = _.cloneDeep(updatedAccountsArray);
            }
          });

          /* Update card profile */
          this.dashboardData.body.cardProfile = cardProfileUpdatedValue;

          this.store.dispatch(dashboardActions.setAccountData({ accounts: this.dashboardData }));
        }
        return res;
      })
    );
  }

  getEditTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'editScheduleTransaction').pipe(map((res) => res));
  }
  currencyPairDetails(payload: object): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'leadLagCurrency').pipe(map((res) => res));
  }
  updateEapLimits(payload: any): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'updateEapLimits')
    .pipe(map((res:any) => {

      if(res.body && !payload.nonFinancialRule){
        this.store.dispatch(loginAction.eapLimitScheme({ eapLimitScheme: res.body.limitScheme.scheme }));
      }

      return res;
    }));
  }

  getTransactionCharges(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'transactions').pipe(map((res) => res));
  }

  cancelScheduleTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'cancelScheduleTransactions').pipe(map((res) => res));
  }
  cancelNextScheduleTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'cancelNextScheduleTransactions').pipe(map((res) => res));
  }

  getReceipt(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'fetchReceipts').pipe(map((res) => res));
  }

  getVASReceipt(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'fetchVASReceipts').pipe(map((res) => res));
  }

  getInstantMoneyReceipt(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'fetchInstantMoneyReceipts').pipe(map((res) => res));
  }

  getFutureTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'futureTransactions').pipe(map((res) => res));
  }

  getIndividualReceiptDetails(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'receiptDetails').pipe(map((res) => res));
  }

  cancelInstantMoney(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'cancelInstant').pipe(map((res) => res));
  }

  fetchGepgReceipts(payload) {
    return this.serviceEndpoint.post('fetchGepgReceipts', payload);
  }

  validateGepgBiller(payload) {
    return this.serviceEndpoint.post('validateGepgBiller', payload).pipe(map((response) => response.body));
  }

  getScheduleTxnReceipt(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'futureTransactionsReceipt').pipe(map((res) => res));
  }

  getInstantMoneyReceipts(payload: ReceiptRequest): Observable<InstantMoneyReceiptsResponse> {
    return this.serviceEndpoint
      .post<ReceiptRequest, InstantMoneyReceiptsResponse>('fetchInstantMoneyReceipts', payload)
      .pipe(map((response) => response.body));
  }

  getBulkInstantMoneyReceipts(payload: ReceiptRequest): Observable<BulkInstantMoneyReceiptsResponse> {
    return this.serviceEndpoint
      .post<ReceiptRequest, BulkInstantMoneyReceiptsResponse>('fetchBulkInstantMoneyReceipts', payload)
      .pipe(map((response) => response.body));
  }

  makeTransaction(payload: TransactionsRequest): Observable<TransactionsResponse> {
    return this.serviceEndpoint.put<TransactionsRequest, TransactionsResponse>('transactions', payload).pipe(
      map((response) => {
        if (response.body && response.body.account[0]) {
          response.body.account.forEach((acc) => {
            const fromAccountUpdatedData = acc;
            let index;
            if (this.accountsList && this.accountsList.length && fromAccountUpdatedData && fromAccountUpdatedData.number) {
              index = _.findIndex(this.accountsList, { number: fromAccountUpdatedData.number });
            }

            if (index >= 0) {
              /* Update Accounts array */
              const updatedAccountsArray = this.accountsList.slice(0);
              updatedAccountsArray[index] = fromAccountUpdatedData;
              this.accountsList = _.cloneDeep(updatedAccountsArray);
              this.dashboardData.body.accounts = _.cloneDeep(updatedAccountsArray);
            }
          });

          this.store.dispatch(dashboardActions.setAccountData({ accounts: this.dashboardData }));
        }

        return response.body;
      })
    );
  }

  getAccountHolderNameService(payload: object) {
    // ToDo: make types for request/response
    return this.serviceEndpoint.post<any, any>('getAccountHolderName', payload).pipe(map((res) => res.body));
  }

  getTxnId(transactionResult) {
    let txnId = '';
    try {
      const transactionResultMetaData = transactionResult.transactionResultMetaData;
      if (transactionResultMetaData) {
        transactionResultMetaData.forEach((value) => {
          if (value.transactionResultKey === 'REFERENCE') {
            txnId = value.value;
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
    return txnId;
  }

  getEditSchedulePayload(data: any, scheduleType: any, oneOffTypeData?: any) {
    let genericDetails: any;
    let paidAmount: any;
    let detailInfo: any;
    const beneficiaryType = ['BANK_BENEFICIARY', 'MOBILE_WALLET'];
    const onceOffType = ['ONCE_OFF_BANK_BENEFICIARY', 'ONCE_OFF_MOBILE_WALLET'];
    const billerType = ['BILLER'];
    const SFTType = ['SELF_FUNDS'];
    const onceOffCompanyType = ['ONCE_OFF_COMPANY'];
    const companyType = ['COMPANY'];
    const editSchedulePayload: any = {
      fromAccount: data.account,
      type: scheduleType,
      amount: {
        amount: '',
        currency: '',
      },
      futureDatedId: '',
      futureDatedInstruction: {
        repeatInterval: '',
        repeatNumber: '',
      },
      recentPayments: '',
      nextPaymentDate: '',
    };
    if (_.indexOf(beneficiaryType, scheduleType) >= 0) {
      paidAmount = data.transactions.beneficiaryPayments[0].amount;
      genericDetails = data.transactions.beneficiaryPayments[0].beneficiary;
      detailInfo = data.transactions.beneficiaryPayments[0];
      editSchedulePayload.beneficiary = {
        accountHolderFullName: genericDetails.accountHolderFullName,
        accountNumber: genericDetails.accountNumber,
        route: genericDetails.route,
        payeeId: genericDetails.payeeId,
        customerReference: genericDetails.customerReference,
        name: genericDetails.name,
        recipientReference: genericDetails.recipientReference,
      };
      if (this.config.allowRtgsWeekend) {
        editSchedulePayload.beneficiary.address = genericDetails.address;
      }
      if (scheduleType === 'MOBILE_WALLET') {
        // need to be decided how to add bank object
      }
    } else if (_.indexOf(onceOffType, scheduleType) >= 0) {
      genericDetails = data.transactions.onceOffPayments[0].beneficiary;
      paidAmount = data.transactions.onceOffPayments[0].amount;
      detailInfo = data.transactions.onceOffPayments[0];
      editSchedulePayload.beneficiary = {
        accountNumber: genericDetails.accountNumber,
        address: genericDetails.address,
        route: genericDetails.route,
        networkType: oneOffTypeData.networkType,
        customerReference: genericDetails.customerReference,
        name: genericDetails.name,
        recipientReference: genericDetails.recipientReference,
      };
    } else if (_.indexOf(SFTType, scheduleType) >= 0) {
      paidAmount = data.editData.scheduledAmount;
      genericDetails = data.editData;
      editSchedulePayload.toAccount = data.selectedToAccount;
      editSchedulePayload.fromAccount = data.selectedFromAccount;
      editSchedulePayload.beneficiary = {
        accountNumber: genericDetails.accountNumber,
        bank: genericDetails.bank,
        route: genericDetails.route,
        networkType: genericDetails.networkType,
        customerReference: genericDetails.customerReference,
        keyValueMetadata: genericDetails.keyValueMetadata,
      };
    } else if (_.indexOf(billerType, scheduleType) >= 0) {
      paidAmount = (data.transactions.rOABillerPayments && data.transactions.rOABillerPayments[0].amount) || data.transactions.cdiPayments[0].amount;
      genericDetails =
        (data.transactions.rOABillerPayments && data.transactions.rOABillerPayments[0].biller) || data.transactions.cdiPayments[0].recipient;
      detailInfo = (data.transactions.rOABillerPayments && data.transactions.rOABillerPayments[0]) || data.transactions.cdiPayments[0];
      editSchedulePayload.beneficiary = null;
      editSchedulePayload.roaBiller = {
        customFields: genericDetails.customFields,
        name: genericDetails.name,
        nickName: genericDetails.nickName,
        roaBillerId: genericDetails.roaBillerId,
        userRef: genericDetails.userRef,
      };
      editSchedulePayload.futureDatedItems = genericDetails.futureDatedItems;
    } else if (_.indexOf(onceOffCompanyType, scheduleType) >= 0) {
      paidAmount = data.transactions.onceOffCdiPayments[0].amount;
      genericDetails = data.transactions.onceOffCdiPayments[0].recipient;
      // genericDetails = data.paymentModel.editData;
      detailInfo = data.transactions.onceOffCdiPayments[0];
      editSchedulePayload.beneficiary = null;
      editSchedulePayload.roaBiller = null;
      editSchedulePayload.toAccount = null;
      editSchedulePayload.futureDatedInstruction = data.transactions.onceOffCdiPayments[0].futureDatedInstruction;
      editSchedulePayload.cdiRecipient = {
        accountCurrency: null,
        accountNumber: null,
        customerReference: genericDetails.customerReference,
        keyValueMetadata: genericDetails.keyValueMetadata,
        name: genericDetails.name,
        nickName: null,
        paymentConfirmation: genericDetails.paymentConfirmation,
        recentPayment: genericDetails.recentPayment,
        recipientGroup: genericDetails.recipientGroup,
        recipientReference: genericDetails.recipientReference,
      };
      editSchedulePayload.recentPayments = [];
      editSchedulePayload.futureDatedItems = [];
    } else if (_.indexOf(companyType, scheduleType) >= 0) {
      paidAmount = data.transactions.cdiPayments[0].amount;
      genericDetails = data.transactions.cdiPayments[0].recipient;
      detailInfo = data.transactions.cdiPayments[0];
      editSchedulePayload.cdiRecipient = {
        accountCurrency: genericDetails.accountCurrency,
        accountNumber: genericDetails.accountNumber,
        customerReference: genericDetails.customerReference,
        keyValueMetadata: genericDetails.keyValueMetadata,
        name: genericDetails.name,
        nickName: genericDetails.nickName,
        payeeId: genericDetails.payeeId,
        paymentConfirmation: genericDetails.paymentConfirmation,
        recentPayment: genericDetails.recentPayment,
        recipientGroup: genericDetails.recipientGroup,
        recipientReference: genericDetails.recipientReference,
      };
      editSchedulePayload.futureDatedItems = genericDetails.futureDatedItems;
    }

    editSchedulePayload.paymentConfirmation = genericDetails.paymentConfirmation;
    if (
      editSchedulePayload.paymentConfirmation &&
      editSchedulePayload.paymentConfirmation.notifications &&
      editSchedulePayload.paymentConfirmation.notifications.length
    ) {
      for (let x = 0; x < editSchedulePayload.paymentConfirmation.notifications.length; x++) {
        editSchedulePayload.paymentConfirmation.notifications[x].type = genericDetails.paymentConfirmation.notifications[x].type.toUpperCase();
      }
    }

    editSchedulePayload.amount = paidAmount;

    editSchedulePayload.futureDatedId = genericDetails.futureDatedId || null;

    editSchedulePayload.nextPaymentDate = genericDetails.nextPaymentDate;

    if (
      genericDetails.futureDatedInstruction &&
      (genericDetails.futureDatedInstruction.repeatInterval === 'SINGLE' || genericDetails.futureDatedInstruction.repeatInterval === 'JUST ONCE')
    ) {
      genericDetails.futureDatedInstruction.repeatInterval = 'SINGLE';
    } else if (genericDetails.futureDatedInstruction && genericDetails.futureDatedInstruction.repeatInterval) {
      genericDetails.futureDatedInstruction.repeatInterval = genericDetails.futureDatedInstruction.repeatInterval.toUpperCase();
    }

    if (scheduleType === 'SELF_FUNDS') {
      editSchedulePayload.futureDatedInstruction.repeatInterval = genericDetails.futureDatedInstruction.repeatInterval;
      if (genericDetails.futureDatedInstruction.repeatInterval === 'SINGLE' || genericDetails.futureDatedInstruction.repeatInterval === 'JUST ONCE') {
        editSchedulePayload.futureDatedInstruction.repeatInterval = 'SINGLE';
        editSchedulePayload.futureDatedInstruction.repeatNumber = 1;
      } else {
        editSchedulePayload.futureDatedInstruction.repeatNumber = genericDetails.futureDatedInstruction.repeatNumber;
      }
      editSchedulePayload.recentPayments = genericDetails.recentPayments;
      if (editSchedulePayload.recentPayments.length) {
        _.each(editSchedulePayload.recentPayments, (value) => {
          delete value.formattedDate;
        });
      }

      return editSchedulePayload;
    }

    if (!data.transactions.onceOffCdiPayments) {
      if (!data.transactions.onceOffPayments) {
        editSchedulePayload.futureDatedInstruction.repeatInterval = genericDetails.futureDatedInstruction.repeatInterval;
        if (genericDetails.futureDatedInstruction.repeatInterval !== 'JUST ONCE') {
          editSchedulePayload.futureDatedInstruction.repeatNumber = genericDetails.futureDatedInstruction.repeatNumber;
        } else {
          editSchedulePayload.futureDatedInstruction.repeatNumber = 1;
        }
      } else {
        editSchedulePayload.futureDatedInstruction = null;
        if (!editSchedulePayload.futureDatedId) {
          editSchedulePayload.futureDatedId = oneOffTypeData.futureDatedId;
          const oneOffMoment: any = moment(oneOffTypeData.nextPaymentDate).utcOffset(this.config.timeOffsetInHolidays);

          editSchedulePayload.nextPaymentDate = oneOffMoment._d;

          if (scheduleType === 'ONCE_OFF_MOBILE_WALLET') {
            editSchedulePayload.recentPayments = [];
            editSchedulePayload.beneficiary.bank = {};
            editSchedulePayload.beneficiary.bank.name = oneOffTypeData.bank.name;
          } else if (scheduleType === 'ONCE_OFF_BANK_BENEFICIARY') {
            editSchedulePayload.beneficiary.bank = genericDetails.bank;
          }
        }
      }
    }

    editSchedulePayload.recentPayments = detailInfo.recentPayments;
    /* _.each(editSchedulePayload.recentPayments, function(value) {
      delete value.formattedDate;
    }); */

    return editSchedulePayload;
  }

  getToastMessageForStopScheduleTransaction(toBeSentForApproval: any, repeatInterval: any, numberOfTransactions: any) {
    let message;
    const isItRecurringTransaction = repeatInterval !== 'SINGLE';

    // When the number of transactions to be stopped are more than 1
    if (numberOfTransactions > 1) {
      if (toBeSentForApproval) {
        message = numberOfTransactions + ' stop requests successfully sent for approval';
      } else {
        message = numberOfTransactions + ' transaction schedules stopped';
      }
    } else {
      // When the number of transactions to be stopped is only 1
      if (!toBeSentForApproval) {
        if (isItRecurringTransaction) {
          message = 'The next payment has been stopped successfully';
        } else {
          message = 'Future payment has been stopped successfully';
        }
      } else {
        message = 'Stop request successfully sent for approval';
      }
    }
    return message;
  }
  getSmeApprovalRules(payload) {
    let state;
    this.store.select('appReducer', 'approvalReducer').subscribe((stateData) => {
      state = stateData;
    });
    switch (payload.approverRulesType) {
      case 'NON_FINANCIAL_RULES':
        if (Object.keys(state.nonFinancial).length) {
          return observableOf(state.nonFinancial);
        } else {
          return this.serviceEndpoint.makeRequest(payload, 'getApproverRules').pipe(
            map((res: any) => {
              // this.store.dispatch(setNonFinancialApprovalData({nonFinancial: res.body.nonFinancialRules}));
              const structuredData = this.approverData.dataStructuring(res.body.nonFinancialRules);
              this.store.dispatch(setNonFinancialApprovalData({ nonFinancial: structuredData }));
              return structuredData;
            })
          );
        }
        break;
      case 'FINANCIAL_RULES':
        if (Object.keys(state.financial).length) {
          return observableOf(state.financial);
        } else {
          return this.serviceEndpoint.makeRequest(payload, 'getApproverRules').pipe(
            map((res: any) => {
              // this.store.dispatch(setNonFinancialApprovalData({nonFinancial: res.body.nonFinancialRules}));
              const structuredData = this.approverData.dataStructuring(res.body.financialRules);
              this.store.dispatch(setFinancialApprovalData({ financial: structuredData }));
              return structuredData;
            })
          );
        }
        break;
    }
  }

  getAccountHolderName(bankBranchCode, model) {
    let bankName;
    let customerReference;
    let recipientReference;
    let accountNumber;
    let customName;
    let route;
    if (model.onceOffBank) {
      route = {
        branch: {
          address: model.selectedBranch.address,
          bank: model.onceOffBank,
          code: model.selectedBranch.code,
          name: model.selectedBranch.name,
        },
        networkType: model.paymentPriority.networkType,
        routingNumber: model.paymentPriority.routingNumber,
      };
      customerReference = model.onceOffMyReference;
      recipientReference = model.onceOffTheirReference;
      customName = model.onceOffBankDetailsName;
      accountNumber = model.onceOffBankDetailsAccountNum;
    } else {
      accountNumber = model.accountNumber;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      bankName = model.detailsBankName.name;
      customerReference = model.myRef;
      recipientReference = model.theirRef;
      customName = model.beneficiaryName;
    }
    const accHolderFullNamePayload = {
      beneficiaries: [
        {
          accountNumber,
          route,
          favourite: false,
          customerReference,
          recipientReference,
          keyValueMetadata: [
            {
              key: 'CUSTOM_BEN_TYPE',
              value: 'BANK_ACCOUNT',
            },
          ],
          name: customName,
          paymentConfirmation: model.paymentConfirmation,
          address: null,
        },
      ],
    };
    return this.serviceEndpoint.makeRequest(accHolderFullNamePayload, 'getAccountHolderName').pipe(map((res) => res));
  }

  fetchQrPaymentDetails(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'fetchQrPaymentDetails').pipe(map((res) => res));
  }

  getInstitutionBillDetails(payload: InstitutionBillDetailsRequest): Observable<InstitutionBillDetailsResponse> {
    return this.serviceEndpoint
      .post<InstitutionBillDetailsRequest, InstitutionBillDetailsResponse>('getInstitutionBillDetails', payload)
      .pipe(map((response) => response.body));
  }
}
