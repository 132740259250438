import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { CmaSubPurposeCodesResponse } from './data/cma-sub-purpose-codes-response';
import { KeyValueMetadata } from '../data/key-value-metadata';
import { CmaPurposeCodesResponse, SubPurposeCodesResponse, Sector } from './data/cma-purpose-codes-response';
import { CmaSubPurposeCodesRequest } from './data/cma-sub-purpose-codes-request';
import { BankingMetadataState } from '../../shared/store-utilities/state/banking-metadata.state';
import {
  setCmaBeneficiaryTypesData,
  setCmaCountryCodesData,
  setCmaPurposeCodesData,
  setCmaSubPurposeCodesData,
  setCountryCodesData,
} from '../../shared/store-utilities/actions/banking-metadata.action';
import { CountryCodesResponse } from './data/country-codes-response';
import { HttpResponse } from '@angular/common/http';
import { BanksListResponse } from './data/banks-list-response';
import { BanksListRequest } from './data/banks-list-request';
import { BanksBranchesListRequest } from './data/banks-branches-list-request';
import { BanksBranchesListResponse } from './data/banks-branches-list-response';
import { CountryCode } from './data/country-code';
import { CmaBeneficiaryTypesResponse } from './data/cma-beneficiary-types-response';
import { Branch } from './data/branch';
import { RouteListByBranchRequest } from './data/route-list-by-branch-request';
import { RouteListByBranchResponse } from './data/route-list-by-branch-response';
import { SraTaxCodesResponse } from './data/cma-sra-tax-codes';
import { PeriodListResponse } from './data/period-list-response';
import { NotificationMethodsResponse } from './data/notification-methods-response';
import { ZimraTaxAccountResponse } from './data/zimra-tax-accounts-response';
import { ZimraTaxCodesResponse } from './data/zimra-tax-codes-response';
import { ZimraTaxOfficeCodesResponse } from './data/zimra-tax-officeCodes-response';
import { AccountCurrenciesList } from './data/file-upload-account-currencies';
import { InstitutionsResponse } from './data/institutions-response';

@Injectable()
export class BankingMetadataService {
  reducerData: {
    exchangeRates: object;
    publicHolidays: object;
    countryCodes: object;
  };

  constructor(
    private serviceEndpoint: ServiceEndpointsService,
    private store: Store<{ appReducer: { bankingMetadataReducer: BankingMetadataState } }>
  ) {}

  public getBanksList(networkType?: string, countries?: CountryCode[]): Observable<HttpResponse<BanksListResponse>> {
    let payload: BanksListRequest;
    if (networkType === 'CMA') {
      payload = {
        includeNetworkTypes: ['CMA'],
      };
    } else if (networkType === 'GIP') {
      payload = {
        includeNetworkTypes: ['GIP'],
      };
    } else if (networkType === 'RTG') {
      payload = {
        excludeNetworkTypes: ['CMA', 'GIP'],
      };
    } else {
      payload = {
        excludeNetworkTypes: ['CMA'],
      };
    }
    if (countries) {
      payload.countries = countries;
    }
    return this.serviceEndpoint.post<BanksListRequest, BanksListResponse>('banksList', payload).pipe(map((res) => res));
  }

  public getBankBranches(bank: object, networkType: string): Observable<HttpResponse<BanksBranchesListResponse>> {
    let payload;
    if (networkType === 'CMA') {
      payload = {
        banks: [bank],
        includeNetworkTypes: ['CMA'],
      };
    } else if (networkType === 'GIP') {
      payload = {
        banks: [bank],
        includeNetworkTypes: ['GIP'],
      };
    } else if (networkType === 'RTG') {
      payload = {
        banks: [bank],
        excludeNetworkTypes: ['CMA', 'GIP'],
      };
    } else if (networkType === 'SWI') {
      payload = {
        includeNetworkTypes: ['SWI'],
      };
    } else {
      payload = {
        banks: [bank],
        excludeNetworkTypes: ['CMA'],
      };
    }
    return this.serviceEndpoint.post<BanksBranchesListRequest, BanksBranchesListResponse>('bankBranches', payload).pipe(map((res) => res));
  }

  public getBranchRoutes(branches: Branch[]): Observable<HttpResponse<RouteListByBranchResponse>> {
    const payload = {
      branches,
    };
    return this.serviceEndpoint.post<RouteListByBranchRequest, RouteListByBranchResponse>('branchRoutes', payload).pipe(map((res) => res));
  }

  public getCountryCodes(): Observable<CountryCodesResponse> {
    let countryCodes: CountryCodesResponse;

    this.store
      .select((state) => state.appReducer.bankingMetadataReducer.countryCodes)
      .subscribe((value) => {
        if (value && value.length) {
          countryCodes = { countryCodes: value };
        }
      });

    if (countryCodes) {
      return observableOf(countryCodes);
    }
    return this.serviceEndpoint.get<CountryCodesResponse>('countryCodes').pipe(
      map((response) => response.body),
      tap((response) => {
        if (response.countryCodes) {
          this.store.dispatch(setCountryCodesData({ countryCodes: response.countryCodes }));
        }
      })
    );
  }

  public getCMACountryCodes(): Observable<any> {
    const payload = {};
    return this.serviceEndpoint.makeRequest(payload, 'cmacountryCodes').pipe(map((res) => res));
  }

  public getExchangeRates(fromCurrency): Observable<any> {
    let exchangeRateList;
    this.store.select('appReducer', 'bankingMetadataReducer').subscribe((data) => {
      exchangeRateList = data.exchangeRates;
    });
    if (exchangeRateList && exchangeRateList[fromCurrency.baseCurrency]) {
      return observableOf(exchangeRateList[fromCurrency.baseCurrency][0]);
    } else {
      return this.serviceEndpoint.makeRequest(fromCurrency, 'exchangeRate').pipe(
        map((res: any) => {
          exchangeRateList = { [fromCurrency.baseCurrency]: [] };

          // todo : ask hemant to replace object.assign
          exchangeRateList[fromCurrency.baseCurrency] = Object.assign([], exchangeRateList[fromCurrency.baseCurrency]);
          exchangeRateList[fromCurrency.baseCurrency].push(res.body.rate);

          //  this.store.dispatch({ type: SAVE_EXCHANGE_RATE, payload: rateList });
          return res.body.rate;
        })
      );
    }
  }

  public publicHolidaysList(): Observable<any> {
    return this.serviceEndpoint.makeRequest({}, 'publicHolidaysList').pipe(map((res) => this.savePublicHoliday(res)));
  }

  public getCutOffTimes(): Observable<any> {
    return this.serviceEndpoint.makeRequest({}, 'settings').pipe(map((res) => this.getSettings(res)));
  }

  internationalPaymentCountryCodes(): Observable<any> {
    return this.serviceEndpoint.makeRequest(null, 'internationalPaymentCountryCodes').pipe(
      map((res) => {
        return res;
      })
    );
  }

  fetchInternationalPaymentRouteDetails(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'fetchInternationalPaymentRouteDetails').pipe(
      map((res) => {
        return res;
      })
    );
  }

  internationalPaymentReasons(): Observable<any> {
    return this.serviceEndpoint.makeRequest(null, 'internationalPaymentReasons').pipe(
      map((res) => {
        return res;
      })
    );
  }

  internationalPaymentChargeOptionList(): Observable<any> {
    return this.serviceEndpoint.makeRequest(null, 'internationalPaymentChargeOptionList').pipe(
      map((res) => {
        return res;
      })
    );
  }

  internationalPaymentCurrencyList(): Observable<any> {
    return this.serviceEndpoint.makeRequest(null, 'internationalPaymentCurrencyList').pipe(
      map((res) => {
        return res;
      })
    );
  }

  internationalPaymentPreInitialisation(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'internationalPaymentPreInitialisation').pipe(
      map((res) => {
        return res;
      })
    );
  }
  getValueValidations(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'valueValidationInternationalpayment').pipe(
      map((res) => {
        return res;
      })
    );
  }
  getBopCodes(): Observable<any> {
    return this.serviceEndpoint.makeRequest(null, 'countryBopCodes').pipe(
      map((res) => {
        return res;
      })
    );
  }
  getInstantMoneyVoucherList() {
    return this.serviceEndpoint.makeRequest(null, 'instantMoneyVoucherList').pipe(
      map((res) => {
        return res;
      })
    );
  }

  getInstantMoneyPurposeList() {
    return this.serviceEndpoint.makeRequest(null, 'instantMoneyPurposeList').pipe(
      map((res) => {
        return res;
      })
    );
  }
  getInsuranceReasons() {
    return this.serviceEndpoint.makeRequest(null, 'InsuranceReasons').pipe(
      map((res) => {
        return res;
      })
    );
  }
  getCmaPurposeCodes(): Observable<CmaPurposeCodesResponse> {
    let purposeCodes: KeyValueMetadata[];

    this.store
      .select((state) => state.appReducer.bankingMetadataReducer.cmaPurposeCodes)
      .subscribe((value) => {
        if (value) {
          purposeCodes = value;
        }
      });
    if (purposeCodes && purposeCodes.length) {
      return observableOf({ cmaPurposes: purposeCodes });
    }
    return this.serviceEndpoint.get<CmaPurposeCodesResponse>('cmaPurposeCodes').pipe(
      map((response) => response.body),
      tap((response) => this.store.dispatch(setCmaPurposeCodesData({ cmaPurposeCodes: response.cmaPurposes })))
    );
  }

  getCmaSubPurposeCodes(cmaPurpose: KeyValueMetadata): Observable<CmaSubPurposeCodesResponse> {
    let cmaSubPurposeCodes: { [key: string]: KeyValueMetadata[] };

    this.store
      .select((state) => state.appReducer.bankingMetadataReducer.cmaSubPurposeCodes)
      .subscribe((value) => {
        if (value) {
          cmaSubPurposeCodes = value;
        }
      });
    if (cmaSubPurposeCodes && cmaSubPurposeCodes[cmaPurpose.key]) {
      return observableOf({ cmaSubPurposes: cmaSubPurposeCodes[cmaPurpose.key] });
    }
    return this.serviceEndpoint.post<CmaSubPurposeCodesRequest, CmaSubPurposeCodesResponse>('cmaSubPurposeCodes', { cmaPurpose }).pipe(
      map((response) => response.body),
      tap((response) => this.store.dispatch(setCmaSubPurposeCodesData({ cmaSubPurposeCodes: { [cmaPurpose.key]: response.cmaSubPurposes } })))
    );
  }

  getCmaCountryCodes(): Observable<CountryCodesResponse> {
    let cmaCountryCodes: CountryCodesResponse;

    this.store
      .select((state) => state.appReducer.bankingMetadataReducer.cmaCountryCodes)
      .subscribe((value) => {
        if (value && value.length) {
          cmaCountryCodes = { countryCodes: value };
        }
      });

    if (cmaCountryCodes) {
      return observableOf(cmaCountryCodes);
    }
    return this.serviceEndpoint.get<CountryCodesResponse>('cmaCountryCodes').pipe(
      map((response) => response.body),
      tap((response) => {
        if (response.countryCodes) {
          this.store.dispatch(setCmaCountryCodesData(response));
        }
      })
    );
  }

  getCmaBeneficiaryTypes(): Observable<CmaBeneficiaryTypesResponse> {
    let cmaBeneficiaryTypes: CmaBeneficiaryTypesResponse;

    this.store
      .select((state) => state.appReducer.bankingMetadataReducer.cmaBeneficiaryTypes)
      .subscribe((value) => {
        if (value && value.length) {
          cmaBeneficiaryTypes = { cmaBeneficiaryTypes: value };
        }
      });

    if (cmaBeneficiaryTypes) {
      return observableOf(cmaBeneficiaryTypes);
    }
    return this.serviceEndpoint.get<CmaBeneficiaryTypesResponse>('cmaBeneficiaryTypes').pipe(
      map((response) => response.body),
      tap((response) => {
        if (response.cmaBeneficiaryTypes) {
          this.store.dispatch(setCmaBeneficiaryTypesData(response));
        }
      })
    );
  }

  getSraYearsPeriod(): Observable<PeriodListResponse> {
    return this.serviceEndpoint.get<PeriodListResponse>('getSraYearsPeriod').pipe(map((res) => res.body));
  }

  getSraTaxCodes(): Observable<SraTaxCodesResponse> {
    return this.serviceEndpoint.get<SraTaxCodesResponse>('getSraTaxCodes').pipe(map((res) => res.body));
  }

  getZimraTaxAccounts(): Observable<ZimraTaxAccountResponse> {
    return this.serviceEndpoint.get<ZimraTaxAccountResponse>('getZimraTaxAccounts').pipe(map((res) => res.body));
  }

  getZimraTaxCodes(): Observable<ZimraTaxCodesResponse> {
    return this.serviceEndpoint.get<ZimraTaxCodesResponse>('getZimraTaxCodes').pipe(map((res) => res.body));
  }

  getZimraTaxOfficeCodes(): Observable<ZimraTaxOfficeCodesResponse> {
    return this.serviceEndpoint.get<ZimraTaxOfficeCodesResponse>('getZimraTaxOfficeCodes').pipe(map((res) => res.body));
  }

  getFileUploadSourceAccountCurrencies(): Observable<AccountCurrenciesList> {
    return this.serviceEndpoint.get<AccountCurrenciesList>('getFileUploadSourceAccountCurrencies').pipe(map((res) => res.body));
  }

  getAvailableDepositPeriods(): Observable<PeriodListResponse> {
    return this.serviceEndpoint.get<PeriodListResponse>('getAvailableDepositPeriodList').pipe(map((response) => response.body));
  }

  getAccountOpeningNotificationMethods(): Observable<NotificationMethodsResponse> {
    return this.serviceEndpoint.get<NotificationMethodsResponse>('getAccountOpeningNotificationMethods').pipe(map((response) => response.body));
  }

  getAvailableCurrencyList(): Observable<any> {
    return this.serviceEndpoint.get<any>('getAvailableCurrencyList').pipe(map((response) => response.body));
  }

  getAccountOpeningCurrencyList() {
    return this.serviceEndpoint.makeRequest(null, 'accountOpeningCurrencyList').pipe(
      map((res) => {
        return res;
      })
    );
  }

  getMinAmountPerCurrencyListCallDeposit() {
    return this.serviceEndpoint.get<any>('getMinAmountPerCurrencyListCallDeposit').pipe(map((response) => response.body));
  }
  getMinAmountPerCurrencyList() {
    return this.serviceEndpoint.get<any>('getMinimumAmountPerCurrencyList').pipe(map((response) => response.body));
  }
  getMoneyTransferTaxCode(): Observable<any> {
    return this.serviceEndpoint.get<any>('getMoneyTransferTaxCodes').pipe(map((response) => response.body));
  }
  getEapLimitSchemes(): Observable<{}> {
    return this.serviceEndpoint.makeRequest({}, 'getEapLimitSchemes').pipe(map((res: any) => res.body));
  }
  getFetchLimitSchemeDefinition(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'getFetchLimitSchemeDefinition').pipe(map((res: any) => res.body));
  }
  getInstitutions(): Observable<InstitutionsResponse> {
    return this.serviceEndpoint.get<InstitutionsResponse>('getSchoolInstitutions').pipe(map((res) => res.body));
  }

  public getPaymentSubPurposes(): Observable<SubPurposeCodesResponse> {
    return this.serviceEndpoint.makeRequest(null, 'paymentSubPurposes').pipe(map((res: any) => res.body));
  }
  public getSector(): Observable<Sector> {
    return this.serviceEndpoint.makeRequest(null, 'sector').pipe(map((res: any) => res.body));
  }

  private addToRateList(res, fromCurrency, rateList): void {
    rateList = rateList || { [fromCurrency.baseCurrency]: [] };

    // todo : ask hemant to replace object.assign
    rateList[fromCurrency.baseCurrency] = Object.assign([], rateList[fromCurrency.baseCurrency]);
    rateList[fromCurrency.baseCurrency].push(res.body.rate);

    return res.body.rate;
  }

  private savePublicHoliday(response) {
    return response.body.publicHolidays;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private getCountryCode() {}

  
  private getSettings(response) {
    return response;
  }
}
