import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  flows = [
    {
      name: 'addBeneficiary',
      steps: [
        '/recipients-management/beneficiary/add-beneficiary-details',
        '/recipients-management/beneficiary/review',
        '/recipients-management/beneficiary/list-beneficiary',
      ],
      config: {
        notificationType: 'toast',
        title: 'Add Beneficiary',
      },
    },
    {
      name: 'editBeneficiary',
      steps: [
        '/recipients-management/beneficiary/edit-beneficiary',
        '/recipients-management/beneficiary/review',
        '/recipients-management/beneficiary/details-beneficiary',
      ],
      config: {
        notificationType: 'toast',
        title: 'Edit Beneficiary',
      },
    },
    {
      name: 'detailsBeneficiary',
      steps: [
        '/recipients-management/beneficiary/details-beneficiary',
        '/recipients-management/beneficiary/list-beneficiary',
      ],
      config: {
        notificationType: 'toast',
        title: 'Details Beneficiary',
      },
    },
    {
      name: 'addBiller',
      steps: [
        '/recipients-management/biller/add-biller',
        '/recipients-management/biller/add-biller-details',
        '/recipients-management/biller/review',
        '/recipients-management/biller/list-biller',
      ],
      config: {
        notificationType: 'toast',
        title: 'Add Biller',
      },
    },
    {
      name: 'payBiller',
      steps: [
        '/payments/biller/details',
        '/payments/biller/review',
        '/payments/biller/receipt',
        '/recipients-management/biller/list-biller',
      ],
      config: {
        notificationType: 'toast',
        title: 'Pay Biller',
      },
    },
    {
      name: 'detailsBiller',
      steps: [
        '/recipients-management/biller/list-biller',
        '/recipients-management/biller/details-biller',
      ],
      config: {
        notificationType: 'toast',
        title: 'Details Biller',
      },
    },
    {
      name: 'editBiller',
      steps: [
        '/recipients-management/biller/edit-biller',
        '/recipients-management/biller/review',
        '/recipients-management/biller/details-biller',
      ],
      config: {
        notificationType: 'toast',
        title: 'Add Biller',
      },
    },
    {
      name: 'onceOffPaymentDetails',
      steps: [
        '/payments/beneficiary/once-off-beneficiary-details',
        '/payments/beneficiary/review',
        '/payments/beneficiary/receipt',
      ],
      config: {
        title: 'Once Off Payment',
      },
    },
    {
      name: 'selfFundTransferDetails',
      steps: [
        '/payments/selfFund/details',
        '/payments/selfFund/confirm',
        '/payments/selfFund/receipt',
      ],
      config: {
        title: 'Self Fund Transfer',
      },
    },
    {
      name: 'unayoPaymentDetails',
      steps: [
        '/payments/unayo/details',
        '/payments/unayo/confirm',
        '/payments/unayo/receipt',
      ],
      config: {
        title: 'Unayo Payment',
      },
    },
    {
      name: 'buyAirtimeDetails',
      steps: [
        '/buy/airtime/details',
        '/buy/airtime/review',
        '/buy/airtime/receipt',
      ],
      config: {
        title: 'Buy Airtime',
      },
    },
    {
      name: 'buyDataDetails',
      steps: ['/buy/data/details', '/buy/data/review', '/buy/data/receipt'],
      config: {
        title: 'Buy Data',
      },
    },
    {
      name: 'buyTVSubscriptionDetails',
      steps: [
        '/buy/tvsubscription/details',
        '/buy/tvsubscription/review',
        '/buy/tvsubscription/receipt',
      ],
      config: {
        title: 'Buy TV Subscription',
      },
    },
    {
      name: 'buyElectricityDetails',
      steps: [
        '/buy/electricity/details',
        '/buy/electricity/review',
        '/buy/electricity/receipt',
      ],
      config: {
        title: 'Buy Electricity',
      },
    },
    {
      name: 'buyWaterDetails',
      steps: ['/buy/water/details', '/buy/water/review', '/buy/water/receipt'],
      config: {
        title: 'Buy Water',
      },
    },
    {
      name: 'instantMoneyDetails',
      steps: [
        '/instantMoney/new',
        '/instantMoney/review',
        '/instantMoney/receipt',
      ],
      config: {
        title: 'Instant Money',
      },
    },
    {
      name: 'internationalPayment',
      steps: [
        '/payments/internationalPayment/yourDetails',
        '/payments/internationalPayment/beneficiaryDetails',
        '/payments/internationalPayment/BoPDeclaration',
        '/payments/internationalPayment/paymentDetails',
        '/payments/internationalPayment/paymentConfirmation',
        '/payments/internationalPayment/paymentResult',
      ],
      config: {
        title: 'International Payment',
      },
    },
    {
      name: 'internationalPayment-mvp1',
      steps: [
        '/payments/internationalPayment/yourDetails',
        '/payments/internationalPayment/beneficiaryDetails',
        '/payments/internationalPayment/BoPDeclaration',
        '/payments/internationalPayment/paymentDetails',
        '/payments/internationalPayment/multipleFile',
        '/payments/internationalPayment/paymentConfirmation',
        '/payments/internationalPayment/paymentResult',
      ],
      config: {
        title: 'International Payment',
      },
    },
    {
      name: 'internationalPaymentsApproval',
      steps: [
        '/approvals/international-payment-approval',
        '/approvals/international-payment-approval-receipt',
      ],
      config: {
        title: 'International Payment Approvals',
      },
    },
    {
      name: 'sendInstantMoneyDetails',
      steps: [
        '/sendMoney/instantMoney/details',
        '/sendMoney/instantMoney/review',
        '/sendMoney/instantMoney/receipt',
      ],
      config: {
        title: 'Instant Money',
      },
    },
    {
      name: 'cancelInstantMoneyDetails',
      steps: [
        '/sendMoney/cancelInstantMoney/details',
        '/sendMoney/cancelInstantMoney/review',
        '/sendMoney/cancelInstantMoney/receipt',
      ],
      config: {
        title: 'Cancel Instant Money',
      },
    },
    {
      name: 'bulkIndividualReceiptList',
      steps: [
        '/sendMoney/cancelInstantMoney/details',
        '/sendMoney/cancelInstantMoney/bulkIndividualReceiptList',
        '/sendMoney/cancelInstantMoney/bulkIndividualReceipt',
      ],
      config: {
        title: 'Bulk Individual Receipt',
      },
    },
    {
      name: 'addBeneficiaryGroup',
      steps: [
        '/recipients-management/beneficiary/groups',
        '/recipients-management/beneficiary/select-beneficiaries/add',
        '/recipients-management/beneficiary/groups',
      ],
      config: {
        title: 'Add Beneficiary Group',
      },
    },
    {
      name: 'addBillerGroup',
      steps: [
        '/recipients-management/biller/groups',
        '/recipients-management/biller/select-billers/add',
        '/recipients-management/biller/groups',
      ],
      config: {
        title: 'Add Beneficiary Group',
      },
    },
    {
      name: 'addCmaBeneficiaryGroup',
      steps: [
        '/recipients-management/cma-beneficiary/cma-groups',
        '/recipients-management/cma-beneficiary/select-cma-beneficiaries/add',
        '/recipients-management/cma-beneficiary/cma-groups',
      ],
      config: {
        title: 'Add CMA Beneficiary Group',
      },
    },
    {
      name: 'editBeneficiaryGroup',
      steps: [
        '/recipients-management/beneficiary/groups',
        '/recipients-management/beneficiary/group-details',
        '/recipients-management/beneficiary/select-beneficiaries/edit',
        '/recipients-management/beneficiary/groups',
      ],
      config: {
        title: 'Edit Beneficiary Group',
      },
    },
    {
      name: 'editBillerGroup',
      steps: [
        '/recipients-management/biller/groups',
        '/recipients-management/biller/group-details',
        '/recipients-management/biller/select-beneficiaries/edit',
        '/recipients-management/biller/groups',
      ],
      config: {
        title: 'Edit Biller Group',
      },
    },
    {
      name: 'editCmaBeneficiaryGroup',
      steps: [
        '/recipients-management/cma-beneficiary/cma-groups',
        '/recipients-management/cma-beneficiary/cma-group-details',
        '/recipients-management/cma-beneficiary/select-cma-beneficiaries/edit',
        '/recipients-management/cma-beneficiary/cma-groups',
      ],
      config: {
        title: 'Edit Beneficiary Group',
      },
    },
    {
      name: 'editCMABeneficiary',
      steps: [
        '/recipients-management/cma-beneficiary/edit-cma-beneficiary',
        '/recipients-management/cma-beneficiary/review',
      ],
      config: {
        title: 'Edit CMA Beneficiary',
      },
    },
    {
      name: 'addCMABeneficiary',
      steps: [
        '/recipients-management/cma-beneficiary/add-cma-beneficiary-details',
        '/recipients-management/cma-beneficiary/review',
      ],
      config: {
        title: 'Add CMA Beneficiary',
      },
    },
    {
      name: 'beneficiaryGroup',
      steps: [
        '/recipients-management/beneficiary/list-beneficiary',
        '/recipients-management/beneficiary/groups',
        '/recipients-management/beneficiary/list-beneficiary',
      ],
      config: {
        title: 'Edit Beneficiary Group',
      },
    },
    {
      name: 'billerGroup',
      steps: [
        '/recipients-management/biller/list-biller',
        '/recipients-management/biller/groups',
        '/recipients-management/biller/list-biller',
      ],
      config: {
        title: 'Edit Beneficiary Group',
      },
    },
    {
      name: 'scheduleTransferDetails',
      steps: ['/scheduleTransaction', '/scheduleTransaction/receipt'],
      config: {
        title: 'Schedule Transfer',
      },
    },
    {
      name: 'mvp0',
      steps: [
        '/offers/consent',
        '/offers/confirm',
        '/offers/details',
        '/offers/t&c',
        '/offers/receipt',
      ],
      config: {
        title: 'Offers page mvp0',
      },
    },
    {
      name: 'mvp1',
      steps: [
        '/offers/consent',
        '/offers/increaseAmt',
        '/offers/details',
        '/offers/t&c',
        '/offers/receipt',
      ],
      config: {
        title: 'Offers page mvp1',
      },
    },
    {
      name: 'mvp2',
      steps: ['/offers/increaseAmt', '/offers/receipt'],
      config: {
        title: 'Offers page mvp2',
      },
    },
    {
      name: 'payBeneficiary',
      steps: [
        '/payments/beneficiary/details',
        '/payments/beneficiary/review',
        '/payments/beneficiary/receipt',
      ],
      config: {
        title: 'Pay Beneficiary',
      },
    },
    {
      name: 'bulkFile',
      steps: [
        '/payments/bulkFileUpload/details',
        '/payments/bulkFileUpload/review',
        '/payments/bulkFileUpload/receipt',
      ],
      config: {
        title: 'BulkFile Module',
      },
    },
    {
      name: 'overdraft',
      steps: [
        '/overdraft/information',
        '/overdraft/details',
        '/overdraft/confirm',
        '/overdraft/receipt',
      ],
      config: {
        title: 'OverDraft Module',
      },
    },
    {
      name: 'debitCardDetails',
      steps: [
        '/debitCards/stopDebitCards/details',
        '/debitCards/stopDebitCards/confirmation',
        '/debitCards/stopDebitCards/receipt',
      ],
      config: {
        title: 'Debit card',
      },
    },
    {
      name: 'reactivateDebitCardDetails',
      steps: [
        '/debitCards/reactivateCards/details',
        '/debitCards/reactivateCards/confirmation',
        '/debitCards/reactivateCards/receipt',
      ],
      config: {
        title: 'Debit card',
      },
    },
    {
      name: 'updateLimitDebitCardDetails',
      steps: [
        '/debitCards/updateLimit/details',
        '/debitCards/updateLimit/confirmation',
        '/debitCards/updateLimit/receipt',
      ],
      config: {
        title: 'Debit card',
      },
    },
    {
      name: 'payMobileWallet',
      steps: [
        '/payments/MobileWallet/details',
        '/payments/MobileWallet/review',
        '/payments/MobileWallet/receipt',
      ],
      config: {
        title: 'Pay Mobile Wallet',
      },
    },
    {
      name: 'payCmaBeneficiary',
      steps: [
        '/payments/cma/details',
        '/payments/cma/review',
        '/payments/cma/receipt',
      ],
      config: {
        title: 'Pay',
      },
    },
    {
      name: 'receipts',
      steps: [
        '/transaction-management',
        '/transaction-management/individual-receipt',
      ],
      config: {
        title: 'Receipts',
      },
    },
    {
      name: 'forgotPassword',
      steps: [
        '/forgotPassword',
        '/forgotPassword/answerSecurityQuestions',
        '/forgotPassword/changePassword',
      ],
      config: {
        title: 'Forgot Password',
      },
    },
    {
      name: 'registration',
      steps: [
        '/registration',
        '/registration/securityQuestions',
        '/registration/verifyUser',
        '/registration/createPassword',
      ],
      config: {
        title: 'Registration',
      },
    },
    {
      name: 'serviceRequest',
      steps: ['/serviceRequests', '/serviceRequests/newServiceRequest'],
      config: {
        title: 'Service Request',
      },
    },
    {
      name: 'newServiceRequest',
      steps: [
        [
          '/serviceRequests/accountModificationRequest',
          '/serviceRequests/loanRequest',
          '/serviceRequests/blueSkyRequest',
          '/serviceRequests/depositBookRequest',
          '/serviceRequests/fixedDepositBreakage',
          '/serviceRequests/renewFixedDeposit',
          '/serviceRequests/overDraftRequest',
          '/serviceRequests/chequeBookRequest',
          '/serviceRequests/stopChequeRequest',
          '/serviceRequests/atmDebitCardRequest',
          '/serviceRequests/eBankingLimitRequest',
          '/serviceRequests/bankCertifiedCheque',
          '/serviceRequests/atmLimitRequest',
          '/serviceRequests/creditCardRequest',
          '/serviceRequests/renewSibekeloAccountRequest',
          '/serviceRequests/requestInsurance',
          '/serviceRequests/fixedDepositIntrestRate',
          '/serviceRequests/cancelNoticeGivenAccount',
          '/serviceRequests/giveNoticeOnNoticeDeposit',
          '/serviceRequests/POSReversal',
        ],
        '/serviceRequests/serviceRequestResult',
      ],
      config: {
        title: 'Service Request',
      },
    },
    {
      name: 'instantPayments',
      steps: [
        '/payments/gip/details',
        '/payments/gip/review',
        '/payments/gip/receipt',
      ],
      config: {
        title: 'Instant Payment',
      },
    },
    {
      name: 'onceOffInstantPayments',
      steps: [
        '/payments/gip/beneficiary',
        '/payments/gip/details',
        '/payments/gip/review',
        '/payments/gip/receipt',
      ],
      config: {
        title: 'Instant Payment',
      },
    },
    {
      name: 'vasPayment',
      steps: [
        '/payments/vas/details',
        '/payments/vas/review',
        '/payments/vas/receipt',
      ],
      config: {
        title: 'Vas Payment',
      },
    },
    {
      name: 'gepg',
      steps: [
        '/payments/gepg/details',
        '/payments/gepg/amount',
        '/payments/gepg/review',
        '/payments/gepg/receipt',
      ],
      config: {
        title: 'GEPG',
      },
    },
    {
      name: 'taxPayment',
      steps: [
        '/payments/taxPayment/details',
        '/payments/taxPayment/confirmation',
        '/payments/taxPayment/receipt',
      ],
      config: {
        title: 'Tax Payment',
      },
    },
    {
      name: 'taxPaymentCDI',
      steps: [
        '/payments/na-tax-payment/details',
        '/payments/na-tax-payment/review',
        '/payments/na-tax-payment/receipt',
      ],
      config: {
        title: 'Tax Payment',
      },
    },
    {
      name: 'onceOffCompanyPayment',
      steps: [
        '/payments/biller/once-off-biller-details',
        '/payments/biller/review',
        '/payments/biller/receipt',
      ],
      config: {
        title: 'One Off Company Payment',
      },
    },
    {
      name: 'termDepositReinvestment',
      steps: [
        '/onlineServiceRequests/termDepositReinvestment/details',
        '/onlineServiceRequests/termDepositReinvestment/review',
        '/onlineServiceRequests/termDepositReinvestment/receipt',
      ],
      config: {
        title: 'Reinvestment Options',
      },
    },
    {
      name: 'ghanaQr',
      steps: [
        '/payments/qr/once-off',
        '/payments/qr/details',
        '/payments/qr/review',
        '/payments/qr/receipt',
      ],
      config: {
        title: 'GHQR Payment',
      },
    },
    {
      name: 'schoolFees',
      steps: [
        '/payments/schoolfees/details',
        '/payments/schoolfees/invoice',
        '/payments/schoolfees/review',
        '/payments/schoolfees/receipt',
      ],
      config: {
        title: 'Pay Fee',
      },
    },
    {
      name: 'internationalPaymentsNew',
      steps: [
        '/payments/crossBorderPayment/yourDetails',
        '/payments/crossBorderPayment/exchange-deal',
        '/payments/crossBorderPayment/beneficiaryDetails',
        '/payments/crossBorderPayment/paymentDetails',
        '/payments/crossBorderPayment/exchangeRateDetails',
        '/payments/crossBorderPayment/multipleFile',
        '/payments/crossBorderPayment/BoPDeclaration',
        '/payments/crossBorderPayment/paymentConfirmation',
        '/payments/crossBorderPayment/paymentResult',
      ],
      config: {
        title: 'International Transfers',
      },
    },
    {
      name: 'cmainternationalPaymentsNew',
      steps: [
        '/payments/crossBorderPayment/yourDetails',
        '/payments/crossBorderPayment/exchange-deal',
        '/payments/crossBorderPayment/beneficiaryDetails',
        '/payments/crossBorderPayment/paymentDetails',
        '/payments/crossBorderPayment/BoPDeclaration',
        '/payments/crossBorderPayment/paymentConfirmation',
        '/payments/crossBorderPayment/paymentResult',
      ],
      config: {
        title: 'International Transfers',
      },
    },
    {
      name: 'internationalPaymentsNewReferenceFlow',
      steps: [
        '/payments/crossBorderPayment/yourDetails',
        '/payments/crossBorderPayment/exchange-deal',
        '/payments/crossBorderPayment/beneficiaryDetails',
        '/payments/crossBorderPayment/enterDealDetails',
        '/payments/crossBorderPayment/paymentDetails',
        '/payments/crossBorderPayment/exchangeRateDetails',
        '/payments/crossBorderPayment/multipleFile',
        '/payments/crossBorderPayment/BoPDeclaration',
        '/payments/crossBorderPayment/paymentConfirmation',
        '/payments/crossBorderPayment/paymentResult',
      ],
      config: {
        title: 'International Transfers',
      },
    },
  ];
}
