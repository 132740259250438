import { createReducer, on } from "@ngrx/store";
import {
  setCmaBeneficiaryTypesData,
  setCmaCountryCodesData,
  setCmaPurposeCodesData,
  setCmaSubPurposeCodesData,
  setCountryCodesData,
  setExchangeRatesData,
  setPublicHolidaysData,
} from "../actions/banking-metadata.action";
import { BankingMetadataState } from "../state/banking-metadata.state";

const bankingMetadataInitState: BankingMetadataState = {
  exchangeRates: [],
  publicHolidays: [],
  countryCodes: [],
  cmaPurposeCodes: [],
  cmaSubPurposeCodes: {},
  cmaCountryCodes: [],
  cmaBeneficiaryTypes: []
};

export const bankingMetadataReducer = createReducer(
  bankingMetadataInitState,
  on(setCountryCodesData, (state, { countryCodes }) => {
    //  console.log(state, countryCodes);
    return { ...state, countryCodes };
  }),
  on(setPublicHolidaysData, (state, { publicHolidays }) => {
    // console.log(state, countryCodes);
    return { ...state, publicHolidays };
  }),
  on(setExchangeRatesData, (state, { exchangeRates }) => {
    // console.log(state, countryCodes);
    return { ...state, exchangeRates };
  }),
  on(setCmaPurposeCodesData, (state, { cmaPurposeCodes }) => {
    return { ...state, cmaPurposeCodes };
  }),
  on(setCmaSubPurposeCodesData, (state, { cmaSubPurposeCodes }) => {
    return { ...state, cmaSubPurposeCodes: { ...state.cmaSubPurposeCodes, ...cmaSubPurposeCodes } };
  }),
  on(setCmaCountryCodesData, (state, { countryCodes }) => {
    return { ...state, cmaCountryCodes: countryCodes };
  }),
  on(setCmaBeneficiaryTypesData, (state, { cmaBeneficiaryTypes }) => {
    return { ...state, cmaBeneficiaryTypes };
  })
);
