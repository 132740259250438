import { createAction, props } from "@ngrx/store";
import { KeyValueMetadata } from "../../../core/data/key-value-metadata";
import { CountryCode } from "../../../core/banking-metadata/data/country-code";

export const setExchangeRatesData = createAction("[Dashboard Component] exchangeRatesData",
  props<{ exchangeRates: Array<{}> }>());

export const setCountryCodesData = createAction("[Dashboard Component] countryCodesData",
  props<{ countryCodes: Array<{}> }>());

export const setPublicHolidaysData = createAction("[Dashboard Component] setPublicHolidaysData",
  props<{ publicHolidays: Array<{}> }>());

export const setCmaPurposeCodesData = createAction("[Dashboard Component] setCmaPurposeCodesData",
  props<{ cmaPurposeCodes: KeyValueMetadata[] }>());

export const setCmaSubPurposeCodesData = createAction("[Dashboard Component] setCmaSubPurposeCodesData",
  props<{ cmaSubPurposeCodes: { [key: string]: KeyValueMetadata[] } }>());

export const setCmaCountryCodesData = createAction("[Dashboard Component] setCmaCountryCodesData",
  props<{ countryCodes: CountryCode[] }>());

export const setCmaBeneficiaryTypesData = createAction("[Dashboard Component] setCmaBeneficiaryTypesData",
  props<{ cmaBeneficiaryTypes: KeyValueMetadata[] }>());
